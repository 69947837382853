import React, { useState } from "react";
import Footer from "./Footer";

const Welcome2 = () => {
  const [formData, setFormData] = useState({
    token: "",
    phoneNumberId: "",
    pin: "",
    email: "",
  });
  const [responseMessage, setResponseMessage] = useState({ visible: false, text: "", success: false });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token, phoneNumberId, pin, email } = formData;

    const webhookUrl =
      "https://webhook.sellflux.com/webhook/v2/form/lead/298c02d30e182a01578906003d1bba04?redirect_url=https%3A%2F%2Fgoogle.com";

    try {
      // Enviar webhook
      await fetch(webhookUrl, {
        method: "POST",
        body: new URLSearchParams({ email }),
      });

      // Enviar PIN para registro no WhatsApp
      const data = {
        messaging_product: "whatsapp",
        pin: pin,
      };

      const response = await fetch(`https://graph.facebook.com/v20.0/${phoneNumberId}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage({ visible: true, text: "PIN enviado com sucesso!", success: true });
      } else {
        setResponseMessage({
          visible: true,
          text: `Erro: ${result.error.message || "Desconhecido"}`,
          success: false,
        });
      }
    } catch (error) {
      setResponseMessage({ visible: true, text: `Erro na requisição: ${error.message}`, success: false });
    }
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#060316",
      color: "white",
    },
    form: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "800px",
      width: "100%",
      color: "black",
    },
    input: {
      width: "98%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      backgroundColor: "#4444aa",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    responseMessage: (success) => ({
      marginTop: "15px",
      padding: "10px",
      backgroundColor: success ? "#d4edda" : "#f8d7da",
      color: success ? "#155724" : "#721c24",
      border: success ? "1px solid #c3e6cb" : "1px solid #f5c6cb",
      borderRadius: "5px",
    }),
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <h2>Enviar código PIN para a Meta</h2>
        <label htmlFor="token">Informe o Token de acesso:</label>
        <input
          type="text"
          id="token"
          style={styles.input}
          placeholder="Digite o Token"
          value={formData.token}
          onChange={handleChange}
          required
        />

        <label htmlFor="phoneNumberId">ID do Número:</label>
        <input
          type="text"
          id="phoneNumberId"
          style={styles.input}
          placeholder="Identificação do número de telefone"
          value={formData.phoneNumberId}
          onChange={handleChange}
          required
        />

        <label htmlFor="pin">Informe o PIN recebido:</label>
        <input
          type="text"
          id="pin"
          style={styles.input}
          placeholder="Digite o PIN"
          value={formData.pin}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">E-mail de acesso:</label>
        <input
          type="email"
          id="email"
          style={styles.input}
          placeholder="E-mail do cliente que vai consultar"
          value={formData.email}
          onChange={handleChange}
        />

        <button type="submit" style={styles.button}>
          Enviar
        </button>

        {responseMessage.visible && (
          <div style={styles.responseMessage(responseMessage.success)}>{responseMessage.text}</div>
        )}
      </form>
      <Footer/>
    </div>
  );
};

export default Welcome2;
