import React, { useState } from "react";
import Footer from "./Footer";

const App = () => {
  const [method, setMethod] = useState("POST"); // Método de requisição (POST ou GET)
  const [url, setUrl] = useState(""); // URL para requisição
  const [response, setResponse] = useState(null); // Resposta da requisição
  const [loading, setLoading] = useState(false); // Controle de loading
  const [responseColor, setResponseColor] = useState("#000"); // Cor da resposta
  const [statusMessage, setStatusMessage] = useState(""); // Mensagem de status (sucesso, erro, etc.)

  const handleSubmit = async () => {
    if (!url) {
      alert("Por favor, insira um link válido.");
      return;
    }

    setLoading(true);
    setStatusMessage(""); // Limpar mensagem de status anterior

    const data = {
      nome: "Teste de Requisição",
      email: "teste@webhookcliente.com",
      telefone: "5511999999999",
    };

    const requestOptions = {
      method: method, // Define o método (POST ou GET)
      headers: {
        "Content-Type": "application/json",
      },
      body: method === "POST" ? JSON.stringify(data) : null, // Apenas envia body se for POST
    };

    try {
      const response = await fetch(url, requestOptions);

      // Tratar a resposta conforme o código de status
      if (response.ok) {
        // Códigos de status 2xx - Sucesso
        const result = await response.json();
        setResponseColor("#28a745"); // Verde para sucesso
        setStatusMessage("Sucesso! A requisição foi processada corretamente.");
        setResponse(JSON.stringify(result, null, 2)); // Formata a resposta em JSON
      } else {
        // Para códigos de status 4xx e 5xx, tratamos de acordo com o código
        let errorMessage = "";
        let errorColor = "#dc3545"; // Cor padrão para erros (vermelho)

        switch (response.status) {
          case 400:
            errorMessage = "Erro 400: Requisição inválida. Verifique os parâmetros enviados.";
            break;
          case 401:
            errorMessage = "Erro 401: Não autorizado. Verifique suas credenciais.";
            break;
          case 403:
            errorMessage = "Erro 403: Proibido. Você não tem permissão para acessar este recurso.";
            break;
          case 404:
            errorMessage = "Erro 404: Não encontrado. Verifique o link da API.";
            break;
          case 500:
            errorMessage = "Erro 500: Erro interno do servidor. Tente novamente mais tarde.";
            break;
          case 502:
            errorMessage = "Erro 502: Bad Gateway. Problema ao acessar o servidor.";
            break;
          case 503:
            errorMessage = "Erro 503: Serviço indisponível. Tente novamente mais tarde.";
            break;
          case 504:
            errorMessage = "Erro 504: Tempo de resposta expirado. Tente novamente mais tarde.";
            break;
          default:
            errorMessage = `Erro ${response.status}: ${response.statusText}`;
        }

        // Se for erro do cliente, exibe com cor amarela
        if (response.status >= 400 && response.status < 500) {
          errorColor = "#ffc107"; // Amarelo para erros do cliente
          setStatusMessage("Falha no cliente! Verifique os parâmetros ou dados enviados.");
        }

        setResponseColor(errorColor);
        setResponse(errorMessage);
      }
    } catch (error) {
      // Tratar erros de rede ou URL inválida
      if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
        setResponseColor("#fd7e14"); // Laranja para erro de rede
        setStatusMessage("Falha na conexão! Não foi possível se conectar ao servidor.");
        setResponse("Erro de Rede: Não foi possível se conectar ao servidor. Verifique a URL ou sua conexão de rede.");
      } else {
        setResponseColor("#dc3545"); // Vermelho para outros erros desconhecidos
        setStatusMessage("Erro desconhecido! Ocorreu um problema ao tentar realizar a requisição.");
        setResponse("Erro desconhecido: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#060316",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          padding: "20px",
          width: "100%",
          maxWidth: "1000px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1 style={{ textAlign: "center", color: "#333" }}>Requisição HTTP</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px", // Espaçamento entre os elementos
            marginBottom: "20px",
          }}
        >
          <div style={{ flexShrink: 0 }}>
            <label htmlFor="method" style={{ display: "block", marginBottom: "5px" }}>
              Método:
            </label>
            <select
              id="method"
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "100px",
                textShadow: `
                  -1px -1px 0 #7ccd9e,  /* Sombra no canto superior esquerdo */
                  1px -1px 0 #7ccd9e,   /* Sombra no canto superior direito */
                  -1px 1px 0 #7ccd9e,   /* Sombra no canto inferior esquerdo */
                  1px 1px 0 #7ccd9e     /* Sombra no canto inferior direito */
                `,
                color: method === "POST" ? "#f4dc88" : "#7ccd9e", // Vermelho para POST, verde escuro para GET
                fontWeight: "bold",
              }}
            >
              <option value="POST">POST</option>
              <option value="GET">GET</option>
            </select>
          </div>
          <div style={{ flexGrow: 0.98 }}>
            <label htmlFor="url" style={{ display: "block", marginBottom: "0px" }}>
              Link:
            </label>
            <input
              id="url"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Insira o link da requisição"
              style={{
                padding: "10px 0",
                borderRadius: "5px",
                margin: "8px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
          </div>
        </div>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4444aa",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
          }}
        >
          {loading ? "Carregando..." : "Enviar"}
        </button>
        <div style={{ marginTop: "20px" }}>
          <h2 style={{ textAlign: "center", color: "#333" }}>Status da Requisição:</h2>
          <p
            style={{
              textAlign: "center",
              color: responseColor, // Cor definida de acordo com a resposta
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {statusMessage}
          </p>
          <h2 style={{ textAlign: "center", color: "#333" }}>Resposta:</h2>
          <pre
            style={{
              backgroundColor: "#f9f9f9",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ddd",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: responseColor, // A cor da resposta será determinada dinamicamente
            }}
          >
            {response}
          </pre>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
