import React, { useState } from "react";
import Footer from "./Footer";

const Welcome = () => {
  const [formData, setFormData] = useState({
    token: "",
    phoneNumberId: "",
    recipientPhone: "",
    selectedType: "", // Novo estado para controlar o tipo selecionado
    templateName: "", // Novo estado para o nome do template
    language: "en_US", // Novo estado para a linguagem
  });

  const [responseMessage, setResponseMessage] = useState({ visible: false, text: "", success: false });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      selectedType: prev.selectedType === value ? "" : value,
      // Limpa o nome do template se "Mensagem" for desmarcado
      templateName: prev.selectedType === "mensagem" ? "" : prev.templateName,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token, phoneNumberId, recipientPhone, selectedType, templateName, language } = formData;

    const sanitizedPhone = recipientPhone.replace(/\D/g, "");

    const webhookUrl =
      "https://webhook.sellflux.com/webhook/v2/form/lead/f2fa82b33410c3a3253129ab6fcc0a47?redirect_url=https%3A%2F%2Fsellflux.com.br";

    try {
      // Enviar webhook
      await fetch(webhookUrl, {
        method: "POST",
        body: new URLSearchParams({ email: "placeholder@example.com" }), // Placeholder para email
      });

      // Enviar template ou mensagem
      let data;
      if (selectedType === "template") {
        data = {
          messaging_product: "whatsapp",
          to: sanitizedPhone,
          type: selectedType,
          template: {
            name: "hello_world", // Nome do template fixo
            language: { code: language }, // Usar a linguagem selecionada
          },
        };
      } else if (selectedType === "mensagem") {
        data = {
          messaging_product: "whatsapp",
          to: sanitizedPhone,
          type: selectedType,
          template: {
            name: templateName, // Nome do template fornecido pelo usuário
            language: { code: language }, // Usar a linguagem selecionada
          },
        };
      }

      const response = await fetch(`https://graph.facebook.com/v20.0/${phoneNumberId}/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage({ visible: true, text: `Sucesso ao enviar ${selectedType} para o número ${recipientPhone}!`, success: true });
      } else {
        const errorMessage = handleApiError(result);
        setResponseMessage({ visible: true, text: errorMessage, success: false });
      }
    } catch (error) {
      setResponseMessage({ visible: true, text: `Ocorreu um erro!\nCódigo: ${error.code || 'N/A'}\nMensagem: ${error.message}`, success: false });
    }
  };

  const handleApiError = (errorData) => {
    const errorCode = errorData.error.code;
    let formattedMessage = '';

    switch (errorCode) {
      case 4:
        formattedMessage = 'O app atingiu o limite da taxa de chamadas de API.';
        break;
      case 0:
        formattedMessage = "Não foi possível autenticar o usuário do app. Geralmente, isso significa que o token de acesso incluído expirou, foi invalidado ou que o usuário do app alterou uma configuração para impedir que todos os apps acessem os dados dele.";
        break;
      case 33:
        formattedMessage = 'O número de telefone comercial foi excluído.';
        break;
      case 100:
        formattedMessage = 'Erro: Parâmetro inválido.';
        break;
      case 190:
        formattedMessage = 'Token expirado ou inválido, revise o token e tente novamente.';
        break;
      case 200:
        formattedMessage = 'Erro: Acesso não autorizado.';
        break;
      case 368: 
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma.';
        break;
      case 400:
        formattedMessage = 'Erro: Requisição malformada.';
        break;
      case 401:
        formattedMessage = 'Erro: Token de acesso inválido.';
        break;
      case 403:
        formattedMessage = 'Erro: Acesso proibido.';
        break;
      case 404:
        formattedMessage = 'Erro: Recurso não encontrado.';
        break;
      case 500:
        formattedMessage = 'Erro: Erro interno do servidor.';
        break;
      case 503:
        formattedMessage = 'Erro: Serviço indisponível.';
        break;
      case 80007:
        formattedMessage = 'A conta do WhatsApp Business atingiu o limite de volume. Consulte os Limites de volume da conta do WhatsApp Business. Tente novamente mais tarde ou reduza a frequência ou a quantidade de consultas da API que o app está fazendo.';
        break;
      case 131048: 
        formattedMessage = 'Falha ao enviar a mensagem devido a um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam.';
        break;
      case 131031:
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma. Ou então, não foi possível verificar os dados incluídos na solicitação em relação ao conjunto de dados na conta do WhatsApp Business (por exemplo, o pin de duas etapas incluído na solicitação está incorreto).';
        break;
      case 131042:
        formattedMessage = 'Ocorreu um erro relacionado à forma de pagamento.';
        break;
      case 131045:
        formattedMessage = 'Ocorreu uma falha ao enviar a mensagem devido a um erro de registro do número de telefone. Esse erro é comum quando o telefone não verificou o pin via SMS.';
        break;
      case 131049:
        formattedMessage = 'A Meta não quer entregare essa mensagem devido: Para manter o engajamento saudável do ecossistema.';
        break;
      case 132001:
        formattedMessage = 'O modelo não existe no idioma especificado ou não foi aprovado.';
        break;
      case 133005:
        formattedMessage = 'O PIN de confirmação em duas etapas está incorreto.';
        break;
      default:
        formattedMessage = 'Erro desconhecido.';
    }

    return `Código: ${errorCode}\nMensagem: ${formattedMessage}`;
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#060316",
      color: "white",
    },
    form: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "800px",
      width: "100%",
      color: "black",
    },
    input: {
      width: "97%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      backgroundColor: "#4444aa",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    checkboxContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "6px 0",
    },
    checkbox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "10px",
    },
    checkboxInput: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
      accentColor: "#4444aa",
    },
    checkboxInputDisable: {
      width: "20px",
      height: "20px",
      pointerEvents: 'none', /* Bloqueia todos os eventos de ponteiro */
      opacity: '0.5',
      accentColor: "#4444aa", // Cor do checkbox quando marcado
      cursor: "not-allowed",
    },
    responseMessage: (success) => ({
      marginTop: "15px",
      padding: "10px",
      backgroundColor: success ? "#d4edda" : "#f8d7da",
      color: success ? "#155724" : "#721c24",
      border: success ? "1px solid #c3e6cb" : "1px solid #f5c6cb",
      borderRadius: "5px",
      whiteSpace: "pre-line", // Para garantir que as quebras de linha sejam respeitadas
    }),
    inlineContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    select: {
      width: "20%", // Largura do seletor de linguagem
      marginRight: '10px'
    },
    templateInput: {
      width: "80%", // Largura do campo de nome do template
    },
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <h2>Envie um template simples</h2>
        <label htmlFor="token">Token de acesso:</label>
        <input
          type="text"
          id="token"
          style={styles.input}
          placeholder="Digite o Token"
          value={formData.token}
          onChange={handleChange}
          required
        />

        <label htmlFor="phoneNumberId">ID do dispositivo:</label>
        <input
          type="text"
          id="phoneNumberId"
          style={styles.input}
          placeholder="Identificação do número de telefone"
          value={formData.phoneNumberId}
          onChange={handleChange}
          required
        />

        <label>Selecione o tipo:</label>
        <div style={styles.checkboxContainer}>
          <div style={styles.checkbox}>
            <span>Hello World</span>
            <input
              type="checkbox"
              value="template"
              checked={formData.selectedType === "template"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInput}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Template</span>
            <input
              type="checkbox"
              value="mensagem"
              checked={formData.selectedType === "mensagem"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInput}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Imagem</span>
            <input
              type="checkbox"
              value="imagem"
              checked={formData.selectedType === "imagem"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInputDisable}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Vídeo</span>
            <input
              type="checkbox"
              value="video"
              checked={formData.selectedType === "video"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInputDisable}
            />
          </div>
        </div>

        {formData.selectedType === "mensagem" && (
          <>
            <label htmlFor="language" style={{ marginRight: "10px", width: "20%" }}>Selecione a Linguagem:</label>
            <div style={styles.inlineContainer}>

              <select
                id="language"
                style={{ ...styles.input, ...styles.select }} // Largura do seletor de linguagem
                value={formData.language}
                onChange={handleChange}
              >
                <option value="pt_BR" selected>Português (Brasil)</option>
                <option value="en_US">Inglês (EUA)</option>
                <option value="pt_PT">Português (Portugal)</option>
                <option value="es_ES">Espanhol (Espanha)</option>
                <option value="fr_FR">Francês (França)</option>
              </select>
              <input
                type="text"
                id="templateName"
                style={{ ...styles.input, ...styles.templateInput }} // Largura do campo de nome do template
                placeholder="Digite o nome do template"
                value={formData.templateName}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}

        <label htmlFor="recipientPhone">Número receptor do template:</label>
        <input
          type="text"
          id="recipientPhone"
          style={styles.input}
          placeholder="Ex: 5551990816730"
          pattern="\d{12,13}"
          value={formData.recipientPhone}
          onChange={handleChange}
          required
        />

        <button type="submit" style={styles.button}>
          Enviar
        </button>

        {responseMessage.visible && (
          <div style={styles.responseMessage(responseMessage.success)}>{responseMessage.text}</div>
        )}
      </form>
      <Footer />
    </div>
  );
};

export default Welcome;