import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const goToApp = () => {
    navigate('/');
  };

  return (
    <div id="custom-footer" className="footer">
      <div className="footer-left">Desenvolvido por Alisson Kopp</div>
      <button className="footer-btn" onClick={goToApp}>
        Voltar para o início
      </button>
      <div className="footer-right">Versão 1.1.3</div>
    </div>
  );
};

export default Footer;

// Estilos embutidos no próprio arquivo JS
const style = document.createElement("style");
style.innerHTML = `
  /* Usar ID exclusivo para garantir que os estilos não sejam sobrescritos */
  #custom-footer {
    display: flex;
    justify-content: space-between;  /* Alinha os itens ao longo do eixo horizontal */
    align-items: center;             /* Alinha os itens ao longo do eixo vertical */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background-color: #060310;
    color: white;
    font-family: Arial, sans-serif;
    z-index: 1000; /* Certifique-se de que o footer está acima de outros elementos */
    padding: 0 20px;  /* Adiciona um pouco de espaçamento nas laterais */
    box-sizing: border-box; /* Garante que o padding não afete a largura total */
  }

  /* Alinhamento e estilo do texto à esquerda */
  #custom-footer .footer-left {
    font-size: 12px;
    padding: 12px;
    flex: 0 1 auto;  /* Não permite que o conteúdo cresça ou encolha */
  }

  /* Centraliza o botão */
  #custom-footer .footer-btn {
    padding: 8px 15px;
    border-radius: 12px;
    font-size: 12px;
    background-color: #4444aa;
    color: white;
    border: none;
    cursor: pointer;
    flex: 1; /* Faz o botão ocupar o espaço disponível */
    margin: 0 10px; /* Adiciona um pouco de espaçamento lateral */
    max-width: 200px; /* Limita a largura do botão */
  }

  /* Alinhamento e estilo do texto à direita */
  #custom-footer .footer-right {
    font-size: 12px;
    padding: 12px;
    text-align: right; /* Garante que o texto à direita esteja bem alinhado */
    flex: 0 1 auto;  /* Não permite que o conteúdo cresça ou encolha */
  }

  /* Estilo do botão na interação */
  #custom-footer .footer-btn:hover {
    background-color: #45a049;
  }
`;

// Aplica o estilo ao documento
document.head.appendChild(style);