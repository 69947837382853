import React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

// Importar as páginas de boas-vindas
import Template from "./pages/Template";
import Pinmeta from "./pages/Pin";
import ConsultaValidade from "./pages/ConsultaValidade";
import Postman from "./pages/Postman";
import DNS from "./pages/DNS";
import JSON from "./pages/JSON";
import Footer from "./pages/Footer-Start";

const Home = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#060316",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "20px",
    },
    button: {
      backgroundColor: "#4444aa",
      color: "white",
      border: "none",
      padding: "15px",
      fontSize: "16px",
      cursor: "pointer",
      borderRadius: "8px",
      textAlign: "center",
    },
    buttonHover: {
      backgroundColor: "#6666cc",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.grid}>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/template")}
        >
          Envio de Template
        </button>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/pin-meta")}
        >
          Envio de PIN
        </button>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/consultar-validade")}
        >
          Consultar Validade
        </button>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/mini-postman")}
        >
          Mini Postman
        </button>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/dns-lookup")}
        >
          Consulta DNS
        </button>
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#6666cc")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#4444aa")}
          onClick={() => navigate("/json-viewer")}
        >
          JSON Viewer
        </button>
      </div>
      <Footer/>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/template" element={<Template />} />
        <Route path="/pin-meta" element={<Pinmeta />} />
        <Route path="/consultar-validade" element={<ConsultaValidade />} />
        <Route path="/mini-postman" element={<Postman />} />
        <Route path="/dns-lookup" element={<DNS />} />
        <Route path="/json-viewer" element={<JSON />} />
      </Routes>
    </Router>
  );
}

export default App;
