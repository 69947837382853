import React, { useState } from "react";
import Footer from "./Footer";

function App() {
  const [domain, setDomain] = useState("");
  const [results, setResults] = useState("");
  const [errors, setErrors] = useState([]);

  // Função para disparar a consulta ao pressionar Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      performLookup();
    }
  };

  const performLookup = async () => {
    if (!domain) {
      alert("Por favor, insira um domínio.");
      return;
    }

    setResults("<p>Carregando...</p>");
    setErrors([]);

    // Adiciona timestamp à URL para evitar cache
    const timestamp = new Date().getTime();

    // Array de requisições DNS com parâmetro de cache-buster
    const queryUrls = [
      `https://dns.google/resolve?name=${domain}&type=A&_=${timestamp}`,
      `https://dns.google/resolve?name=${domain}&type=AAAA&_=${timestamp}`,
      `https://dns.google/resolve?name=${domain}&type=MX&_=${timestamp}`,
      `https://dns.google/resolve?name=${domain}&type=TXT&_=${timestamp}`,
      `https://dns.google/resolve?name=_dmarc.${domain}&type=TXT&_=${timestamp}`,
      `https://dns.google/resolve?name=sfx._domainkey.${domain}&type=TXT&_=${timestamp}`,
      `https://dns.google/resolve?name=2019._domainkey.${domain}&type=TXT&_=${timestamp}`,
      `https://dns.google/resolve?name=rdns.mail.${domain}&type=A&_=${timestamp}`,
      `https://dns.google/resolve?name=rdns.mail6.${domain}&type=AAAA&_=${timestamp}`,
    ];

    try {
      const responses = await Promise.all(
        queryUrls.map((url) => fetch(url, { cache: "no-store" }).then((res) => res.json()))
      );

      const [
        aRecords,
        aaaaRecords,
        mxRecords,
        txtRecords,
        dmarcRecords,
        dkimSfx,
        dkim2019,
        rdnsMailA,
        rdnsMailAAAA,
      ] = responses;

      let errorOutput = "";
      let resultOutput = "";
      let spfRecordCount = 0;
      let spfRecords = [];
      let txtResults = [];

      let errorList = [];

      // Processando resultados A
      if (aRecords.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-a">A</span> Registros de Endereço</div>
            ${aRecords.Answer.map((record) => `<p>Endereço: ${record.data}</p>`).join("")}
          </div>`;
      } else {
        resultOutput += `<div class="result-box"><div class="result-title"><span class="badge badge-a">A</span> Registros de Endereço</div><p>Nenhum registro A encontrado.</p></div>`;
      }

      // Processando resultados AAAA
      if (aaaaRecords.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-aaaa">AAAA</span> Registros IPv6 </div>
            ${aaaaRecords.Answer.map((record) => `<p>Endereço IPv6: ${record.data}</p>`).join("")}
          </div>`;
      } else {
        resultOutput += `<div class="result-box"><div class="result-title"><span class="badge badge-aaaa">AAAA</span> Registros IPv6 </div><p>Nenhum registro AAAA encontrado.</p></div>`;
      }

      // Processando resultados MX
      if (mxRecords.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-mx">MX</span> Registros de E-mail</div>
            ${mxRecords.Answer.map((record) => `<p>Servidor de E-mail: ${record.data}</p>`).join("")}
          </div>`;
      } else {
        resultOutput += `<div class="result-box"><div class="result-title"><span class="badge badge-mx">MX</span> Registros de E-mail</div><p>Nenhum registro MX encontrado.</p></div>`;
      }

      // Processando resultados TXT e agrupando registros de texto
      txtRecords.Answer?.forEach((record) => {
        if (record.data.startsWith("v=spf1")) {
          spfRecordCount++;
          spfRecords.push(record.data);
        } else {
          txtResults.push(record.data);
        }
      });

      // Exibindo todos os registros TXT na mesma caixa
      if (txtResults.length > 0) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-txt">TXT</span> Registros de Texto </div>
            ${txtResults.map((txt) => `<p>${txt}</p>`).join("")}
          </div>`;
      } else {
        resultOutput += `<div class="result-box"><div class="result-title"><span class="badge badge-txt">TXT</span> Registros de Texto</div><p>Nenhum registro TXT encontrado.</p></div>`;
      }

      // Validação de SPF
      if (spfRecordCount > 1) {
        errorList.push('Erro: Mais de um registro SPF "v=spf1" encontrado!');
        spfRecords.forEach((spf) => {
          resultOutput += `<div class="result-box spf-invalid"><div class="result-title"><span class="badge badge-txt">SPF</span> <span class="highlight-invalid">SPF Inválido:</span></div><p>${spf}</p></div>`;
        });
      } else if (spfRecordCount === 1) {
        const spf = spfRecords[0];
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-txt">SPF</span> Registro SPF</div>
            <p>${spf}</p>
          </div>`;
      } else {
        resultOutput += `<div class="result-box spf-invalid"><div class="result-title"><span class="badge badge-txt">SPF</span> <span class="highlight-invalid">SPF Inválido ou não encontrado</span> </div></div>`;
      }

      // Processando resultados DMARC
      if (dmarcRecords.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-dmarc">DMARC</span> Registros DMARC</div>
            ${dmarcRecords.Answer.map((record) => `<p>Registro DMARC: ${record.data}</p>`).join("")}
          </div>`;
      } else {
        resultOutput += `<div class="result-box"><div class="result-title"><span class="badge badge-dmarc">DMARC</span> Registros DMARC</div><p>Nenhum registro DMARC encontrado.</p></div>`;
      }

      // Processando resultados DKIM agrupados na mesma caixa
      const dkimResults = [];
      if (dkimSfx.Answer) {
        dkimSfx.Answer.forEach((record) => dkimResults.push(`DKIM Seletor "sfx": ${record.data}`));
      }

      if (dkim2019.Answer) {
        dkim2019.Answer.forEach((record) => dkimResults.push(`DKIM Seletor "2019": ${record.data}`));
      }

      if (dkimResults.length > 0) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-dkim">DKIM</span> Registros DKIM (${domain})</div>
            ${dkimResults.map((dkim) => `<p>${dkim}</p>`).join("")}
          </div>`;
      }

      // Exibir registros de rdns.mail (A) caso existam
      if (rdnsMailA.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-a">rdns.mail A</span> Registro rdns.mail (${domain})</div>
            ${rdnsMailA.Answer.map((record) => `<p>Endereço: ${record.data}</p>`).join("")}
          </div>`;
      }

      // Exibir registros de rdns.mail6 (AAAA) caso existam
      if (rdnsMailAAAA.Answer) {
        resultOutput += `
          <div class="result-box">
            <div class="result-title"><span class="badge badge-aaaa">rdns.mail6 AAAA</span> Registro rdns.mail (${domain})</div>
            ${rdnsMailAAAA.Answer.map((record) => `<p>Endereço IPv6: ${record.data}</p>`).join("")}
          </div>`;
      }

      // Caso existam erros, exiba
      if (errorList.length > 0) {
        errorOutput += `<div class="error-message"><p>${errorList.join("<br />")}</p></div>`;
      }

      setErrors(errorOutput);
      setResults(resultOutput);
    } catch (error) {
      setResults("<p>Erro ao tentar realizar a consulta.</p>");
      setErrors(["Erro ao tentar realizar a consulta"]);
    }
    
  };

  return (
    <div className="container">
      <h1 className="title">Consulta DNS</h1>
      <input
        className="input-field"
        type="text"
        placeholder="Digite o domínio"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button className="btn" onClick={performLookup}>
        Consultar
      </button>

      <div className="errors">{errors}</div>
      <div className="results" dangerouslySetInnerHTML={{ __html: results }} />
      <Footer />
      
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #060316;
          }
          
          .container {
            width: 90%;
            max-width: 1000px;
            margin: 0 auto;
            text-align: center;
            padding: 20px;
          }
          
          .title {
            font-size: 2.5em;
            margin-bottom: 20px;
            color: white;
          }
          
          .input-field {
            padding: 12px;
            width: 70%;
            max-width: 400px;
            margin-right: 10px;
            font-size: 1em;
            border-radius: 5px;
            border: 1px solid #ccc;
          }
          
          .btn {
            padding: 12px 20px;
            font-size: 1em;
            background-color: #4caf50;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          
          .btn:hover {
            background-color: #45a049;
          }
          
          .result-box {
            background-color: #f9f9f9;
            border-radius: 10px;
            padding: 15px;
            margin-top: 20px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            word-wrap: break-word;
            overflow-wrap: break-word;
          }
          
          .result-title {
            font-size: 1.25em;
            margin-bottom: 10px;
          }
          
          .badge {
            padding: 3px 7px;
            background-color: #333;
            color: white;
            font-size: 0.875em;
            border-radius: 5px;
          }
          
          .highlight-invalid {
            color: red;
          }
          
          .error-message {
            color: red;
            font-size: 0.875em;
            margin-top: 10px;
          }
          
          .results {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 20px;
            margin-top: 20px;
            text-align: left;
          }
          
          .badge-a {
            background-color: #2196f3;
          }
          
          .badge-aaaa {
            background-color: #ff5722;
          }
          
          .badge-mx {
            background-color: #8bc34a;
          }
          
          .badge-txt {
            background-color: #ff9800;
          }
          
          .badge-dmarc {
            background-color: #9c27b0;
          }
          
          .badge-dkim {
            background-color: #3f51b5;
          }
          
          .spf-invalid {
            background-color: #f44336;
            color: white;
          }

          @media screen and (max-width: 600px) {
            .input-field {
              width: 100%;
              max-width: 100%;
            }
            
            .btn {
              width: 100%;
            }
            
            .container {
              width: 95%;
              padding: 10px;
            }
            
            .title {
              font-size: 2em;
            }
          }
        `}
      </style>
    </div>
  );
}

export default App;
