import React, { useState } from "react";
import Footer from "./Footer";

const App = () => {
  const [phoneId, setPhoneId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault(); // Previne o recarregamento da página

    // URL da API do WhatsApp Cloud
    const apiUrl = `https://graph.facebook.com/v16.0/${phoneId}?fields=verified_name,code_verification_status,display_phone_number,quality_rating,platform_type,throughput,webhook_configuration&access_token=${accessToken}`;

    // URL do Webhook
    const webhookUrl = 'https://webhook.sellflux.com/webhook/v2/form/lead/7b04fb1bdb3c9575b152d8f4292b17ea?redirect_url=https%3A%2F%2Fsellflux.com.br';

    // Enviar o webhook primeiro
    const formData = new FormData();
    formData.append('email', email);

    fetch(webhookUrl, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log('Webhook enviado com sucesso:', data);
      })
      .catch(error => {
        console.error('Erro ao enviar webhook:', error);
      })
      .finally(() => {
        // Fazer a consulta à API do WhatsApp Cloud, independentemente do sucesso do webhook
        fetch(apiUrl)
          .then(response => {
            if (!response.ok) {
              return response.json().then(errorData => {
                console.error('Erro da API:', errorData);
                const errorMessage = handleApiError(errorData);
                throw new Error(errorMessage);
              });
            }
            return response.json();
          })
          .then(data => {
            setResult(data); // Atualiza o estado com os dados retornados
          })
          .catch(error => {
            setResult({ error: `Ocorreu um erro!\n${error.message}` });
            console.error('Erro ao consultar API do WhatsApp:', error);
          });
      });
  };

  const handleApiError = (errorData) => {
    const errorCode = errorData.error.code;
    let formattedMessage = '';

    switch (errorCode) {
      case 4:
        formattedMessage = 'O app atingiu o limite da taxa de chamadas de API.';
        break;
      case 0:
        formattedMessage = "Não foi possível autenticar o usuário do app. Geralmente, isso significa que o token de acesso incluído expirou, foi invalidado ou que o usuário do app alterou uma configuração para impedir que todos os apps acessem os dados dele.";
        break;
      case 33:
        formattedMessage = 'O número de telefone comercial foi excluído.';
        break;
      case 100:
        formattedMessage = 'Erro: Parâmetro inválido.';
        break;
      case 190:
        formattedMessage = 'Token expirado ou inválido, revise o token e tente novamente.';
        break;
      case 200:
        formattedMessage = 'Erro: Acesso não autorizado.';
        break;
      case 368: 
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma.';
        break;
      case 400:
        formattedMessage = 'Erro: Requisição malformada.';
        break;
      case 401:
        formattedMessage = 'Erro: Token de acesso inválido.';
        break;
      case 403:
        formattedMessage = 'Erro: Acesso proibido.';
        break;
      case 404:
        formattedMessage = 'Erro: Recurso não encontrado.';
        break;
      case 500:
        formattedMessage = 'Erro: Erro interno do servidor.';
        break;
      case 503:
        formattedMessage = 'Erro: Serviço indisponível.';
        break;
      case 80007:
        formattedMessage = 'A conta do WhatsApp Business atingiu o limite de volume. Consulte os Limites de volume da conta do WhatsApp Business. Tente novamente mais tarde ou reduza a frequência ou a quantidade de consultas da API que o app está fazendo.';
        break;
      case 131048: 
        formattedMessage = 'Falha ao enviar a mensagem devido a um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam.';
        break;
      case 131031:
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma. Ou então, não foi possível verificar os dados incluídos na solicitação em relação ao conjunto de dados na conta do WhatsApp Business (por exemplo, o pin de duas etapas incluído na solicitação está incorreto).';
        break;
      case 131042:
        formattedMessage = 'Ocorreu um erro relacionado à forma de pagamento.';
        break;
      case 131045:
        formattedMessage = 'Ocorreu uma falha ao enviar a mensagem devido a um erro de registro do número de telefone. Esse erro é comum quando o telefone não verificou o pin via SMS.';
        break;
      case 131049:
        formattedMessage = 'A Meta não quer entregare essa mensagem devido: Para manter o engajamento saudável do ecossistema.';
        break;
      case 132001:
        formattedMessage = 'O modelo não existe no idioma especificado ou não foi aprovado.';
        break;
      case 133005:
        formattedMessage = 'O PIN de confirmação em duas etapas está incorreto.';
        break;
      default:
        formattedMessage = 'Erro desconhecido.';
    }

    return `Código: ${errorCode}\nMensagem: ${formattedMessage}`;
  };

  return (
    <div className="body">
      <div className="container">
        <h1>Consulta validade - Whatsapp Cloud</h1>
        <form id="combinedForm" onSubmit={handleSubmit}>
          <label htmlFor="phoneId">ID do Número:</label>
          <input
            type="text"
            id="phoneId"
            value={phoneId}
            onChange={(e) => setPhoneId(e.target.value)}
            placeholder="Digite o ID do número de telefone"
            required
          />

          <label htmlFor="accessToken">Token Permanente:</label>
          <input
            type="text"
            id="accessToken"
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            placeholder="Digite o token de acesso"
            required />

          <label htmlFor="email">E-mail de acesso:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail do cliente que vai consultar"
          />

          <button type="submit">Consultar</button>
        </form>
        {/* Mover o resultado para cá */}
        {result && (
          <div id="result" className="result">
            {result.error ? (
              <p style={{ color: 'red', whiteSpace: 'pre-line' }}>{result.error}</p> // Renderiza a mensagem de erro com quebras de linha
            ) : (
              <>
                <p><strong>Nome Verificado:</strong> {result.verified_name}</p>
                <p><strong>Status de Verificação:</strong> {result.code_verification_status}</p>
                <p><strong>Número de Telefone:</strong> {result.display_phone_number}</p>
                <p><strong>Avaliação de Qualidade:</strong> {result.quality_rating}</p>
                <p><strong>Tipo de Plataforma:</strong> {result.platform_type}</p>
                <p><strong>Level de Throughput:</strong> {result.throughput.level}</p>
                <p><strong>URL do Webhook:</strong> {result.webhook_configuration.application}</p>
              </>
            )}
          </div>
        )}
      </div>
      <Footer />

      <style>{`
        .body {
          font-family: 'Arial', sans-serif;
          background-color: #060316;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }

        .container {
          background-color: white;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          padding: 20px;
          max-width: 800px;
          color: #333;
          display: flex;
          flex-direction: column;
        }

        h1 {
          text-align: center;
          margin-bottom: 20px;
          color: #060316;
        }

        form {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-bottom: 5px;
          color: #555;
        }

        input {
          padding: 10px;
          margin-bottom: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        button {
          padding: 10px;
          background-color: #4444aa;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        button:hover {
          background-color: #218838;
        }

        .result {
          margin-top: 20px;
          background-color: #f8f9fa;
          padding: 15px;
          border-radius: 5px;
          display: block; // Certifique-se de que a exibição está correta
          word-wrap: break-word;
          color: #333;
        }

        .result p {
          margin-bottom: 10px;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default App;