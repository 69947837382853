import React, { useState } from "react";
import Footer from "./Footer";

const appContainerStyle = {
  backgroundColor: "#060316", // Define o fundo da aplicação
  color: "#ffffff", // Texto claro para contraste com o fundo
  width: "80%",
  maxWidth: "2200px",
  padding: "40px",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  marginTop: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "80vh", // Altura mínima para ocupar boa parte da tela
  margin: "auto", // Centraliza horizontalmente
};

const jsonContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  marginBottom: "30px",
  justifyContent: "space-between",
  width: "100%",
};

const jsonInputStyle = {
  width: "48%",
  height: "500px",
  padding: "20px",
  fontSize: "14px",
  fontFamily: "monospace",
  border: "2px solid #ccc",
  borderRadius: "8px",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  backgroundColor: "#fafafa",
  overflowY: "auto",  // Habilita rolagem interna
};

const jsonOutputStyle = {
  width: "48%",
  height: "500px",
  padding: "20px",
  border: "2px solid #ccc",
  borderRadius: "8px",
  fontFamily: "monospace",
  fontSize: "14px",
  backgroundColor: "#f9f9f9",
  overflowY: "auto",  // Habilita rolagem interna
};

const caminho = {
  width: "48%",
  height: "20px",
  overflowY: "auto",
  padding: "30px",
  border: "2px solid #ccc",
  borderRadius: "8px",
  fontFamily: "monospace",
  fontSize: "14px",
  backgroundColor: "#f9f9f9",
  color: "#000",
  textAlign: "center",
  marginBottom: '60px',
};

/*const inputStyle = { Relacionado ao JSON compacto
  width: "100%",
  height: "100%",
  padding: "10px",
  fontSize: "14px",
  border: "none",
  fontFamily: "monospace",
  outline: "none",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  resize: "none",
};*/
const inputStyle = {
  width: "97%",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  backgroundColor: "#f4f4f4",
  padding: "6px 6px 0",
  borderRadius: "5px",
  border: "1px solid #ccc",
  fontFamily: "monospace",
  fontSize: "14px",
  color: "#000",
  textDecoration: "none",
  height: "400px",  // Limita a altura para o campo ser rolável
  overflowY: "auto",  // Habilita a rolagem vertical
  resize: "none",
};

const buttonStyle = {
  backgroundColor: "#4444aa",
  color: "white",
  fontSize: "16px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "100%",
  marginBottom: "20px",
};

const preStyle = { /*Relacionado ao JSON Formatado*/
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  backgroundColor: "#f4f4f4",
  padding: "2px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  fontFamily: "monospace",
  fontSize: "14px",
  color: "#000",
  textDecoration: "none",
  maxHeight: "400px",  // Limita a altura para o campo ser rolável
  overflowY: "auto",  // Habilita a rolagem vertical
};

const stickyTitleStyle = {
  position: "sticky",
  top: "0",   // Fixa o título no topo do contêiner
  backgroundColor: "#060316",  // Cor de fundo para que o título seja visível ao rolar
  zIndex: "10", // Garante que o título fique acima do conteúdo
  padding: "10px 0", // Adiciona espaçamento para o título
};


const spanStyle = {
  cursor: "pointer",
  color: "blue",
};

function App() {
  const [jsonInput, setJsonInput] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [selectedPath, setSelectedPath] = useState("");

  const formatJson = () => {
    try {
      const json = JSON.parse(jsonInput);
      setFormattedJson(JSON.stringify(json, null, 2));
    } catch (error) {
      alert("JSON inválido!");
    }
  };

  const handleClickPath = (path) => {
    setSelectedPath(path);
  };

  const renderJson = (obj, path = "") => {
    if (typeof obj === "object" && !Array.isArray(obj)) {
      return (
        <div style={{ paddingLeft: "20px" }}>
          {Object.keys(obj).map((key) => {
            const newPath = path ? `${path}.${key}` : key;
            return (
              <div key={newPath}>
                <span
                  onClick={() => handleClickPath(newPath)}
                  style={spanStyle}
                >
                  {key}
                </span>
                : {renderJson(obj[key], newPath)}
              </div>
            );
          })}
        </div>
      );
    } else if (Array.isArray(obj)) {
      return (
        <div>
          {obj.map((item, index) => {
            const newPath = `${path}[${index}]`;
            return (
              <div key={newPath}>
                <span
                  onClick={() => handleClickPath(newPath)}
                  style={spanStyle}
                >
                  {index}
                </span>
                : {renderJson(item, newPath)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return <span>{JSON.stringify(obj)}</span>;
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#060316", minHeight: "100vh" }}>
        <div style={appContainerStyle}>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Formatador de JSON
          </h1>
          <div style={jsonContainerStyle}>
            <div style={jsonInputStyle}>
              <h2 style={stickyTitleStyle}>JSON Compacto</h2>
              <textarea
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder="Cole o JSON compactado aqui"
                style={inputStyle}
              />
            </div>
            <div style={jsonOutputStyle}>
              <h2 style={stickyTitleStyle}>JSON Formatado</h2>
              <pre style={preStyle}>
                {renderJson(JSON.parse(formattedJson || "{}"))}
              </pre>
            </div>
          </div>
          <button onClick={formatJson} style={buttonStyle}>
            Formatar JSON
          </button>
          <h2>Caminho Selecionado:</h2>
          <div style={caminho}>{selectedPath}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
